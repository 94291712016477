<template>
  <div id="language">
    <p class="title">{{$t('settings.language.title')}}</p>
    <b-form-select autofocus v-model="selected" :options="options"></b-form-select>
    <p class="note">{{$t('settings.language.note')}}</p>
    <div class="save" @click="apply">{{$t('settings.save')}}</div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      selected: window.userLocale,
      options: []
    }
  },
  created() {
    window.allLocales.forEach(locale => {
      this.options.push(
        {
          value: locale,
          text: this.$t('locales.' + locale)
        }
      )
    })
  },
  methods: {
    apply() {
      localStorage.lang = this.selected

      this.$api.patch(`user`, {
        prefferedLanguageCode: this.selected
      })
      window.userLocale = this.selected
      this.$i18n.locale = this.selected
      this.$api.defaults.headers.common.Locale = window.userLocale
    }
  }
}
</script>

<style lang="scss">
  #language {
    margin: 0 auto;
    max-width: 280px;
    font-family: Roboto;

    .title {
      color: #47525E;
      font-size: 32px;
      margin-bottom: 68px;
      text-align: center;
      line-height: 30px;
    }
    .note {
      color: #47525E;
      font-size: 14px;
      padding-left: 2px;
      margin-bottom: 20px;
    }
    .save {
      background-color: #47525E;
      border-radius: 5px;
      height: 35px;
      line-height: 35px;
      color: #FFFFFF;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      transition: .2s all;
      &:hover {
        filter: brightness(1.2);
      }
    }
  }
</style>